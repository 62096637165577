.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


:root {
  --easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
  --easeInOutBack: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  --easeOutElastic: cubic-bezier(0.87, -0.41, 0.19, 1.44);
  --easeInOutBounce: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
