#unbox-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh; /* Full viewport height for perfect vertical centering */
    margin: 0 auto;
    text-align: center;
  }
  
  #cardList {
    height: 100px;
    width: 250px;
    position: relative;
    border: 1px solid black;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .card {
    display: inline-block;
    background-color: red;
    text-align: center;
    border-left: 1px solid black;
    border-right: 1px solid black;
    width: 100px;
    height: 100px;
  }
  
  .card > img {
    width: 100px;
    height: 100px;
  }
  
  .arrow-down {

    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #f00;
  }
  
  #dialog-msg > img {
    width: 150px;
    height: 150px;
  }
  
  #dialog-msg {
    text-align: center;
  }

